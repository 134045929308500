import jwt from 'jwt-decode';
import config from '../config.json';

import {
    retrieveTokens as cognitoRetrieveTokens,
    refreshToken as cognitoRefreshToken
} from './cognito';

const accessToken = `${config.clientId}-access_token`,
    tempRedirect = `${config.clientId}-temp-redirect`,
    authorizationCode = `${config.clientId}-authorization-code`,
    idToken = `${config.clientId}-id_token`,
    ls = localStorage,
    loginUrl = `${config.cognitoDomain}/login?response_type=code&client_id=${config.clientId}&redirect_uri=${config.callbackUrl}&state=active`,
    logoutUrl = `${config.cognitoDomain}/logout?client_id=${config.clientId}&logout_uri=${config.logoutUrl}`,
    signupUrl = `${config.cognitoDomain}/signup?response_type=code&client_id=${config.clientId}&redirect_uri=${config.callbackUrl}&state=active`;

const isAuthenticated = () => {
    let hasValidSession = false;
    const valToken = ls.getItem(accessToken);

    // check if localStorage has accessToken & it hasn't expired yet
    if (valToken) {
        const tokens = jwt(valToken),
            timeDiff =  ((tokens.exp * 1000) - new Date().getTime());

        if (timeDiff > 0) {
            hasValidSession = true;
        }
    }

    return hasValidSession;
}

const hasLoggedIn = () => {
    const valToken = ls.getItem(accessToken);
    if (valToken) {
        return true;
    } else {
        return false;
    }
}

const retrieveTokens = async (code) => {
    ls.setItem(authorizationCode, code);
    try {
        setTokens(await cognitoRetrieveTokens(code));
    } catch (e) {
        console.log(e);
    }
}

const refreshToken = async () => {
    try {
        console.log('refreshing token...');
        setTokens(await cognitoRefreshToken(ls.getItem(authorizationCode),
            ls.getItem(`${config.clientId}-refresh_token`)));
        console.log('refreshing token done!');
    } catch (e) {
        console.log(e);
        // something wrong occurred - cleaning up session and redirecting to login
        cleanupSession();
        authRedirect('/');
    }
}

const setTokens = (data) => {
    Object.keys(data).forEach(key=>{ ls.setItem(config.clientId + '-' + key, data[key]) });

    // TODO: ugly
    let tempRedirectValue = ls.getItem(tempRedirect);
    if (tempRedirectValue) ls.removeItem(tempRedirect);
    else tempRedirectValue = '/';

    // redirect
    window.location = tempRedirectValue;
}

const authRedirect = (pathname) => {
    // remember what url path is being requested
    ls.setItem(tempRedirect, pathname);

    window.location = `${config.cognitoDomain}/login?response_type=code&client_id=${config.clientId}&redirect_uri=${config.callbackUrl}&state=active`;
}

const getAuthorizationToken = async () => {
    if (isAuthenticated()) {
        return ls.getItem(idToken);
    } else {
        // refresh tokens
        // if error, send for auth redirection
        await refreshToken();
        return ls.getItem(idToken);
    }
}

const cleanupSession = () => {
    const keys = [];
    for (let i=0;i<ls.length;i++) {
        const k = ls.key(i);
        if (k.includes(config.clientId)) {
            keys.push(k);
        }
    }
    keys.forEach(k=>{
        ls.removeItem(k);
    })
}

export { isAuthenticated, authRedirect, retrieveTokens, refreshToken, getAuthorizationToken, hasLoggedIn, cleanupSession, loginUrl, logoutUrl, signupUrl };
