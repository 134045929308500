export default function reducer(state = { station: undefined, history: undefined }, action) {
    switch (action.type) {
        case 'ON_SELECT': {
            return {
                ...state,
                station: action.station
            };
        }
        case 'ON_STATION_HISTORY': {
            return {
                ...state,
                history: action.history
            };
        }
        case 'ON_RESET': {
            return {
                ...state,
                station: undefined,
                history: undefined
            }
        }
        default: {
            return {...state};
        }
    }
}

export const Types = {
    ON_SELECT: 'ON_SELECT',
    ON_STATION_HISTORY: 'ON_STATION_HISTORY',
    ON_RESET: 'ON_RESET'
}
