import { Types as StationTypes } from '../reducers/station';
import { Types as FeatureTypes } from '../reducers/feature';

const selectStation = station => {
    return dispatch => {
        dispatch({
            type: StationTypes.ON_SELECT,
            station
        });
    }
}

const dispatchUrls = (urls) => {
    return async dispatch => {
        dispatch({
            type: FeatureTypes.ON_SET_URLS,
            urls, urlsTimestamp: new Date().getTime()
        });
    }
}

const setStationHistory = history => {
    return dispatch => {
        dispatch({
            type: StationTypes.ON_STATION_HISTORY,
            history
        });
    }
}

const reset = () => {
    return dispatch => {
        dispatch({ type: StationTypes.ON_RESET });
    }
}

export default reset;

export { reset, setStationHistory, dispatchUrls, selectStation };
