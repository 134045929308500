import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Popup.css'
import { Panel, Table, Image } from 'react-bootstrap'
import moment from 'moment-timezone'
import templates from '../assets/templates.json'
import ScatterGraph from './ScatterGraph'
import Preloader from './Preloader'

export default class Popup extends Component {

    evaluateCardinalDirection(angle) {
      if (typeof angle === 'string') angle = parseInt(angle, 10);

      if (angle <= 0 || angle > 360 || typeof angle === 'undefined') return '☈';
      const arrows = { north: 'N ↓', north_east: 'NE ↙', east: 'E ←', south_east: 'SE ↖', south: 'S ↑', south_west: 'SW ↗', west: 'W →', north_west: 'NW ↘' };
      const directions = Object.keys(arrows);
      const degree = 360 / directions.length;
      angle = angle + degree / 2;
      for (let i = 0; i < directions.length; i++) {
        if (angle >= (i * degree) && angle < (i + 1) * degree) return arrows[directions[i]];
      }
      return arrows['north']; //Catch all
    }

    rows(weather) {
        let trs = [];
        templates.popup.sheet.forEach((sheet,c)=>{
            const val = weather[sheet.value.value];
            if (val) {
                trs.push(
                    <tr key={c}>
                        <th>{sheet.label}</th>
                        <td>{val}{sheet.value.suffix}</td>
                    </tr>
                );
            }
        })
        return trs;
    }

    popup(station, history) {
        const details = station.stationDetails,
            weather = station.weather[0],
            link = `/station/${details.STN}/${details.TYP.toLowerCase()}`;
        return (
            <div>
                <Panel>
                    <Panel.Heading>

                    <div style={styles.headerDiv}>
                        <Panel.Title componentClass="h2">{details.NM}</Panel.Title>
                        <div id="observed">{moment(weather['UTC']).calendar()}</div>
                    </div>
                    <div style={styles.imgDiv}>
                        <Image style={{maxHeight: '100%',maxWidth: '100%'}} src={"/station_logos/" + String(details.Logo)} />
                    </div>
                        
                    </Panel.Heading>
                    <Table condensed responsive id="popup-summary">
                        <tbody>
                            {this.rows(weather)}
                            <tr>
                                <td colSpan="2">
                                    <ScatterGraph summary={history}/>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Panel.Footer>
                        <Link to={link}>View Details</Link>
                    </Panel.Footer>
                </Panel>
            </div>
        )
    }

    render() {
        const { station, history } = this.props;

        if (station) {
            return this.popup(station, history)
        } else {
            return (
                <Panel>
                    <Panel.Heading/>
                    <Preloader/>
                    <Panel.Footer/>
                </Panel>
            )
        }
    }
}

const styles = {
    imgDiv: {
        width: '27%',
        position: 'absolute',
        right: '3%',
        top: 5,
        height: 50
    },
    headerDiv: {
        position:'relative',
        width: '70%'
    }
}