import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ScatterGraph from '../components/StationLayout/ScatterGraph';
import CurrentStatus from '../components/StationLayout/CurrentStatus';
import WebCams from '../components/StationLayout/WebCams';
import Preloader from '../components/Preloader';

import { Glyphicon } from 'react-bootstrap';

import './StationLayout.css';

import Header from '../components/Header';

import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import { getStationHistory } from '../lib/api'
export default class StationLayout extends Component {
    state = { data: undefined }

    componentDidMount = async () => {
        await this.load();
        const interval = setInterval(this.load.bind(this), 60000);
        this.setState({interval});
        //enableBodyScroll(this.targetElement);
        //enableBodyScroll(document.getElementById('station-page'));
        clearAllBodyScrollLocks();
    }

    componentWillUnmount = () => {
        clearInterval(this.state.interval);
    }

    load = async () => {
        const {id,type} = this.props.match.params;
        this.setState({ data: await getStationHistory(id, type, 'Full') });
    }

    render() {
        const { data } = this.state;
        return (
            <div>
                <Header/>
                {
                    (data)
                        ? <Display data={this.state.data}/>
                        : <Preloader style={{marginTop:300}}/>
                }
            </div>
        );
    }
}
const Display = ({data}) => {
    return (
        <React.Fragment>
            <div style={styles.pageHeader}>
                <div style={styles.pageTitleBlock}>
                    <div style={styles.imgDiv}><img style={styles.imageHeader} src={`/station_logos/${data.stationDetails.Logo}`} alt="logo"/></div>
                    <div style={styles.headerDiv}><h1 style={{fontSize: '4vw'}}>{data.stationDetails.NM}</h1></div>
                </div>
                <Link to="/" style={{marginRight:20}} title="Go Back to Map">
                    <Glyphicon glyph="globe" style={{fontSize: 20}}/>
                </Link>
            </div>
            <div  id="station-page" >
                <CurrentStatus summary={data.current} />
                <WebCams summary={data.stationDetails} />
                <ScatterGraph summary={data.weather} />
            </div>
        </React.Fragment>
    )
}
const styles = {
    imgDiv: {
        maxWidth: '20%'
    },
    headerDiv: {
        position:'relative',
        left:'10%'
    },
    imageHeader: {
        display: 'block',
        width: 'auto',
        maxHeight: 100,
        maxWidth: '100%',
        marginRight: 80,
        marginLeft: 20
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10
    },
    pageTitleBlock: {
        flexDirection:'row',
        display:'flex'
    },
    close: {
        alignSelf: 'flex-start'
    }
};