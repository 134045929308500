import React, { Component } from 'react';
import {Scatter} from 'react-chartjs-2';
import {Line} from 'react-chartjs-2';
import './Scatter.css';
import Preloader from '../Preloader'
import { Panel } from 'react-bootstrap'

import { evaluateCardinalDirection, getFilteredData, getWindSpeedGradient } from '../../lib/graph-util';

export default class ScatterGraph extends Component {
    getTempHumidity(canvas) {
        const { summary } = this.props,
            average = getFilteredData(summary, 'T'),
            max = getFilteredData(summary, 'T'),
            gradient = getWindSpeedGradient(canvas, average, max);
        return {
            datasets: [{
                fill: false,
                lineTension: 0.3,
                borderColor: '#505050',
                borderDash: [10,5],
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: getFilteredData(summary, 'RH'),
                showLine: true,
                yAxisID: 'B',
                label: "Relative Humidity"
            },{
                fill: true,
                lineTension: 0.3,
                borderColor: 'transparent',
                borderWidth: 1,
                backgroundColor: gradient,
                
                pointRadius: 0,
                pointHitRadius: 10,
                data:  getFilteredData(summary, 'T'),
                showLine: true,
                yAxisID: 'A',
                label: "Temperature"
            }]
        }
    }

    getWindDirection = () => {
        const { summary } = this.props;
        return {
            datasets: [{
                fill: false,
                lineTension: 0.3,
                borderColor: '#848484',
                borderWidth: 2,
                pointBorderColor: '#848484',
                pointBackgroundColor: '#848484',
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#848484',
                pointHoverBorderColor: '#848484',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 10,
                data: getFilteredData(summary, 'D'),
                showLine: true,
                label: "Wind Direction"
            }]
        }
    }


    getSolarIrradiance = () => {
        const { summary } = this.props;
        return {
            datasets: [{
                fill: true,
                lineTension: 0.3,
                borderColor: 'transparent',
                borderWidth: 1,
                backgroundColor: "rgba(245, 194, 100, 0.8)",
                pointBorderColor: '#F5C264',
                pointBackgroundColor: '#F5C264',
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#F5C264',
                pointHoverBorderColor: '#F5C264',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 10,
                data: getFilteredData(summary, 'SLR'),
                showLine: true,
                label: "Solar Irradiance"
            }]
        }
    }

    getPressure = () => {
        const { summary } = this.props;
        return {
            datasets: [{
                fill: false,
                lineTension: 0.3,
                borderColor: '#848484',
                borderWidth: 3,
                pointBorderColor: '#848484',
                pointBackgroundColor: '#848484',
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#848484',
                pointHoverBorderColor: '#848484',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 10,
                data: getFilteredData(summary, 'QFE'),
                showLine: true,
                label: "Pressure"
            }]
        }
    }

    getRain = () => {
        const { summary } = this.props;
        return {
            datasets: [{
                fill: true,
                lineTension: 0.3,
                borderColor: "rgb(15, 82, 186, 0.7)",
                backgroundColor: "rgb(15, 82, 186, 0.7)",
                borderWidth: 3,
                pointBorderColor: '#0F52BA',
                pointBackgroundColor: '#0F52BA',
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#0F52BA',
                pointHoverBorderColor: '#0F52BA',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 10,
                data: getFilteredData(summary, 'RI'),
                showLine: true,
                label: "Rainfall"
            }]
        }
    }

    getWindSpeed(canvas) {
        const { summary } = this.props,
            average = getFilteredData(summary, 'S'),
            max = getFilteredData(summary, 'SX'),
            gradient = getWindSpeedGradient(canvas, average, max);
        return {
            datasets: [{
                fill: false,
                lineTension: 0.3,
                borderColor: '#505050',
                borderDash: [10,5],
                borderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: getFilteredData(summary, 'SX'),
                showLine: true,
                label: "10 minute Gust speed"
            },{
                fill: true,
                lineTension: 0.3,
                borderColor: 'transparent',
                borderWidth: 1,
                backgroundColor: gradient,
                pointBorderColor: '#1481e0',
                pointBackgroundColor: '#1481e0',
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#1481e0',
                pointHoverBorderColor: '#1481e0',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 10,
                data: average,
                showLine: true,
                label: "10 minute Average speed"
            }]
        }
    }
    


    render() {
        const { summary } = this.props;
        if (summary && summary.length > 0) {
            const WindSpeedOptions = getWindSpeedOptions();            
            const WindDirectionOptions = getWindDirectionOptions();
            const SolarIrradianceOptions = getSolarIrradianceOptions();
            const PressureOptions = getPressureOptions();
            const RainOptions = getRain();
            const TempHumidityOptions = getTempHumidity();

            if (summary[summary.length - 1]["D"] > 270 || summary[summary.length - 1]["D"] < 90) { //switch scale
                WindDirectionOptions.scales.yAxes[0].ticks.min = -180;
                WindDirectionOptions.scales.yAxes[0].ticks.max = 180;
            }


            return (
                <div>
                    <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2">Temperature and Relative Humidity</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '200px'}}>
                                <Line data={this.getTempHumidity.bind(this)} options={TempHumidityOptions} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2">Wind</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '200px'}}>
                                <Line data={this.getWindSpeed.bind(this)} options={WindSpeedOptions} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                            <div id="mini-graph" style={{height: '200px'}}>
                                <Scatter data={this.getWindDirection.bind(this)} options={WindDirectionOptions} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2">Solar Irradiance</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '200px'}}>
                                <Scatter data={this.getSolarIrradiance.bind(this)} options={SolarIrradianceOptions} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2">Rain</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '200px'}}>
                                <Scatter data={this.getRain.bind(this)} options={RainOptions} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>

                    <Panel>
                        <Panel.Heading>
                            <Panel.Title componentClass="h2">Pressure</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <div id="mini-graph" style={{height: '200px'}}>
                                <Scatter data={this.getPressure.bind(this)} options={PressureOptions} datasetKeyProvider={()=>Math.random()}/>
                            </div>
                        </Panel.Body>
                    </Panel>
                    
                </div>
            );
        } else if (summary.length === 0) {
            return <React.Fragment/>
        } else {
            return <Preloader/>
        }
    }
}

const getWindSpeedOptions = () => ({
    maintainAspectRatio: false,
    tooltipTemplate: v => v,
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'H:mm' },
                tooltipFormat: 'H:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                stepSize: 5,
                suggestedMin: 0
            },
            scaleLabel: {
                display: true,
                labelString: 'Wind Speed (Knots)'
            }
        }]
    },
    legend: { display: true }
})

const getWindDirectionOptions = () => ({
    maintainAspectRatio: false,
    tooltipTemplate: v => v,
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'H:mm' },
                tooltipFormat: 'H:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                stepSize: 90,
                min: -180,
                max: 360,
                callback: value => evaluateCardinalDirection(value)
            }
        }]
    },
    legend: { display: false }
});

const getSolarIrradianceOptions = () => ({
    maintainAspectRatio: false,
    tooltipTemplate: v => v,
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'H:mm' },
                tooltipFormat: 'H:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                min: 0
            },
            scaleLabel: {
                display: true,
                labelString: 'W/m2'
            }
        }]
    },
    legend: { display: false }
});


const getPressureOptions = () => ({
    maintainAspectRatio: false,
    tooltipTemplate: v => v,
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'H:mm' },
                tooltipFormat: 'H:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12
            },
            scaleLabel: {
                display: true,
                labelString: 'hPa'
            }
        }]
    },
    legend: { display: false }
});


const getRain = () => ({
    maintainAspectRatio: false,
    tooltipTemplate: v => v,
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'H:mm' },
                tooltipFormat: 'H:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                stepSize: 5,
                suggestedMin: 0
            },
            scaleLabel: {
                display: true,
                labelString: 'mm/10min'
            }
        }]
    },
    legend: { display: false }
})


const getTempHumidity = () => ({
    maintainAspectRatio: false,
    tooltipTemplate: v => v,
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'H:mm' },
                tooltipFormat: 'H:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [
            {
                id: 'A',
                type: 'linear',
                position: 'left',
                scaleLabel: {
                    display: true,
                    labelString: 'Temperature'
                  },
                ticks: {
                    fontSize: 12,
                    stepSize: 5,
                    suggestedMin: 0
                }
              }, {
                id: 'B',
                type: 'linear',
                position: 'right',
                scaleLabel: {
                    display: true,
                    labelString: 'Humidity %'
                  },
                ticks: {
                    fontSize: 12,
                    stepSize: 20,
                    min: 0,
                    max: 100
                }
              }
            ]
    },
    legend: { display: true }
})
