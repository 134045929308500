import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { loginUrl, logoutUrl, hasLoggedIn, signupUrl } from '../lib/auth';

import { disableBodyScroll } from 'body-scroll-lock';

import logo from '../assets/apple-touch-icon-white-57x57.png'
export default class Header extends React.Component {
    componentDidMount() {
        disableBodyScroll(document.getElementById('main-header'));
    }

    render = () => {
        return (
            <Navbar id="main-header" inverse collapseOnSelect staticTop={true} fluid={true} style={{marginBottom: 0, color: '#ccc'}}>
                <Navbar.Header>
                    <Navbar.Brand>
                    <Link style={{padding:0}} to="/"><img style={{position:'relative', display: 'inline-block', width: 45, height: 45, marginLeft:20, marginRight:20 }} src={logo} alt="Weather Watcher Logo" /><span>Weather Watcher</span></Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight>
                        <NavItem eventKey={1} href="/about" target="_blank">About</NavItem>
                        { hasLoggedIn() ? <Logout/> : <Login/> }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

const Logout = () => (
    <NavItem eventKey={3} href={logoutUrl}>
        Logout
    </NavItem>
)

const Login = () => (
    <React.Fragment>
        <NavItem eventKey={2} href={loginUrl}>
            Login
        </NavItem>
        <NavItem eventKey={4} href={signupUrl}>
            SignUp
        </NavItem>
    </React.Fragment>
)