import React, { Component } from 'react';
import Preloader from '../Preloader'
import { Panel, Table } from 'react-bootstrap'
import moment from 'moment-timezone'

import { evaluateCardinalDirection } from '../../lib/graph-util';

export default class ScatterGraph extends Component {

    createTooltip(v) {
        return v
    }

    render() {
        const { summary } = this.props;
        if (summary && summary.LastObservationUTC) {
            const { LastObservationUTC, min1, min10, min30 } = summary;
            return (
                <Panel>
                    <Panel.Heading>
                        <Panel.Title componentClass="h2">Current Conditions - Last Observation - {moment(LastObservationUTC).calendar()}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <Table style={{maxWidth: '100%', marginRight: 'auto', marginLeft: 'auto'}} responsive>
                            <tbody>
                                <tr>
                                    <th>Wind</th>
                                    <th>1 min</th>
                                    <th>10 min</th>
                                    <th>30 min</th>
                                </tr>
                                <tr>
                                    <th>Average</th>
                                    <td>
                                        {min1.averageSpeed} Knots
                                    </td>
                                    <td>
                                        {min10.averageSpeed} Knots
                                    </td>
                                    <td>
                                        {min30.averageSpeed} Knots
                                    </td>
                                </tr>
                                <tr>
                                    <th>Gust</th>
                                    <td>
                                        {min1.maxSpeed} Knots
                                    </td>
                                    <td>
                                        {min10.maxSpeed} Knots
                                    </td>
                                    <td>
                                        {min30.maxSpeed} Knots
                                    </td>
                                </tr>
                                <tr>
                                    <th>Lull</th>
                                    <td>
                                        {min1.minSpeed} Knots
                                    </td>
                                    <td>
                                        {min10.minSpeed} Knots
                                    </td>
                                    <td>
                                        {min30.minSpeed} Knots
                                    </td>
                                </tr>
                                <tr>
                                    <th>Direction</th>
                                    <td>
                                        {min1.averageDirection}° {evaluateCardinalDirection(min1.averageDirection)}
                                    </td>
                                    <td>
                                        {min10.averageDirection}° {evaluateCardinalDirection(min10.averageDirection)}
                                    </td>
                                    <td>
                                        {min30.averageDirection}° {evaluateCardinalDirection(min30.averageDirection)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Panel.Body>
                </Panel>
            );
        } else if (summary) {
            return <React.Fragment/>
        } else {
            return <Preloader/>
        }
    }
}