import olMap from 'ol/map';
import olView from 'ol/view';
import olProj from 'ol/proj';
import olOverlay from 'ol/overlay';

import 'ol/ol.css';

import olFormatGeoJSON from 'ol/format/geojson';
import olSourceVector from 'ol/source/vector';
import olLayerVector from 'ol/layer/vector';

import olStyleStyle from 'ol/style/style';
import olStyleText from 'ol/style/text';
import olStyleIcon from 'ol/style/icon';

import osm from './layers/osm';
import layers from '../assets/layers.json';

import { getStation, getStationHistory, getFeatures } from './api';
import { asyncForEach } from './util';

const factories = {osm};

const loadMap = (mapDiv) => {
    let map = new olMap({
        loadTilesWhileAnimating: true,
        target: mapDiv,
        layers: [],
        view: new olView({
            center: olProj.fromLonLat([144.993011, -37.978845]),
            zoom: 9,
            minZoom: 4,
            maxZoom: 19,
            enableRotation: false
        })
    });

    addLayers(map);
    return map;
}

const registerEvent = (map, selectStation, setStationHistory, reset, popupDiv) => {
    const overlay = getOverlay(map, popupDiv);

    map.on('click', async e => {
        overlay.setPosition();
        const features = map.getFeaturesAtPixel(e.pixel);

        if (features) {
            const feature = features[0],
                coordinates = feature.getGeometry().getCoordinates();

            overlay.setPosition(coordinates);
            reset();

            try {
                const { STN, TYP } = Object.assign({},feature['values_']);
                selectStation(await getStation(STN, TYP));
                setStationHistory(await getStationHistory(STN, TYP));
            } catch (e) { console.log(e); }
        }
    })
}

const getOverlay = (map, popupDiv) => {
    const container = document.getElementById(popupDiv),
        overlay = new olOverlay({
            id: popupDiv,
            element: container,
            autoPan: true,
            autoPanAnimation: {
              duration: 250
            }
        })

    map.addOverlay(overlay);
    return overlay;
}

const loadFeatures = async (map, _layers) => {
    if (_layers && _layers.length > 0) {
        await asyncForEach(_layers, async info => {
            const data = await getFeatures(info.url);
            if (data.status !== 500) {
                data.features.forEach(feat => { feat.properties['TYP'] = info.name; });
                const source = new olSourceVector({wrapX: false}),
                features = new olFormatGeoJSON().readFeatures(data, {featureProjection:'EPSG:3857'});

                setPointStyle(features, info.color)
                source.addFeatures(features);

                // remove layer if it exists before adding it back
                removeLayer(map, info);

                map.addLayer(new olLayerVector({source: source, name: info.name}));
            }
        })
    }
}

const removeLayer = (map, info) => {
    let tempLayer = undefined;
    map.getLayers().forEach(l=>{
        if (info.name === l.values_.name) {
            tempLayer = l;
        }
    })

    if (tempLayer) {
        map.removeLayer(tempLayer);
    }
}

const addLayers = (map) => {
    layers.basemap.forEach((layer) => {
        const type = layer.layer.type;
        if (type in factories) {
            map.addLayer(factories[type](layer.layer));
        }
    });
}

const setPointStyle = (features) => {
    features.forEach((feat)=>{
        var labelValue = '';
        if(feat.values_.S || (feat.values_.S === 0)) {
            labelValue = Math.round(feat.values_.S).toString();
        }
        var windAngle = 0
        if(feat.values_.D) {
            windAngle = Math.round(feat.values_.D);
        }

        var style = new olStyleStyle({
            image: new olStyleIcon(/** @type {olx.style.IconOptions} */ ({
                anchor: [0.5, 0.7078],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: 'circle-large-point.svg',
                rotation: ((windAngle + 180) * Math.PI / 180) //α(180) × π / 180° //310//windAngle Note the plus 180 is due to the degrees representing where the wind is from. For the angle to point correclty it needs to 180 degrees from that.
            })),
            text: new olStyleText({
                font: 'bold 13px helvetica,sans-serif',
                text: labelValue
            })
        })

        if (feat.values_.S === 0) {
            style = new olStyleStyle({
                image: new olStyleIcon(/** @type {olx.style.IconOptions} */ ({
                    anchor: [0.5, 0.5],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: 'circle.svg',
                    rotation: ((windAngle + 180) * Math.PI / 180) //α(180) × π / 180° //310//windAngle Note the plus 180 is due to the degrees representing where the wind is from. For the angle to point correclty it needs to 180 degrees from that.
                })),
                text: new olStyleText({
                    font: 'bold 13px helvetica,sans-serif',
                    text: labelValue
                })
            })
        }

        feat.setStyle(style);
    });
}

export { loadMap, registerEvent, loadFeatures }
