import React, { Component } from 'react';
import Preloader from '../Preloader'
import { Panel, Table } from 'react-bootstrap'
import moment from 'moment-timezone'

import { evaluateCardinalDirection } from '../../lib/graph-util';

export default class ScatterGraph extends Component {

    createTooltip(v) {
        return v
    }

    render() {
        const { summary } = this.props;
        if (summary && summary.UTC) {
            //const { LastObservationUTC, min1, min10, min30 } = summary;
            return (
                <Panel>
                    <Panel.Heading>
                        <Panel.Title componentClass="h2">Current Conditions - Last Observation - {moment(summary.UTC).calendar()}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>


                        <Table style={{maxWidth: '100%', marginRight: 'auto', marginLeft: 'auto'}} responsive>
                            <tbody>
                                <tr>
                                    <th>Temperature</th>
                                    <td>{Math.round(summary.T)}&deg; - Wind Chill ({Math.round(summary.WCT)}&deg;)</td>
                                </tr>
                                <tr>
                                    <th>Relative Humidity</th>
                                    <td>{Math.round(summary.RH)}%</td>
                                </tr>
                                <tr>
                                    <th>Wind</th>
                                    <td>{Math.round(summary.S * 10)/10} - {evaluateCardinalDirection(summary.D)}</td>
                                </tr>

                                <tr>
                                    <th>Rain Intensity</th>
                                    <td>{summary.RI}</td>
                                </tr>
                                <tr>
                                    <th>Solar irradiance</th>
                                    <td>{summary.SLR} W/m²</td>
                                </tr>
                                <tr>
                                    <th>Dew Point</th>
                                    <td>{Math.round(summary.DP * 10)/10}&deg;</td>
                                </tr>
                                <tr>
                                    <th>Pressure</th>
                                    <td>{summary.QFE} hPa</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Panel.Body>
                </Panel>
            );
        } else if (summary) {
            return <React.Fragment/>
        } else {
            return <Preloader/>
        }
    }
}