import axios from 'axios';
import config from '../config.json';
import qs from 'query-string';
import { hasLoggedIn, getAuthorizationToken } from '../lib/auth';

const getUrls = async () => {
    if (hasLoggedIn()) {
        return await authenticatedUrls();
    } else {
        return await anonymousUrls();
    }
}

const getStation = async (STN, type) => {
    const parameters = qs.stringify({STN,type}),
        logged = hasLoggedIn(),
        path = `${ logged ? Urls.AUTHENTICATED_STATION_INFO : Urls.ANONYMOUS_STATION_INFO }?${parameters}`;
    return await get(path, logged)
}

const getStationHistory = async (STN, type, details) => {
    const parameters = qs.stringify({
            details: details ? details : 'windonly',
            period: 240,
            STN, type
        }),
        logged = hasLoggedIn(),
        path = `${ logged ? Urls.AUTHENTICATED_STATION_HISTORY : Urls.ANONYMOUS_STATION_HISTORY }?${parameters}`;

    return await get(path, logged);
}


const getStationHistory24hr = async (STN, type, details) => {
    const parameters = qs.stringify({
            details: details ? details : 'windonly',
            period: 1440,
            STN, type
        }),
        logged = hasLoggedIn(),
        path = `${ logged ? Urls.AUTHENTICATED_STATION_HISTORY : Urls.ANONYMOUS_STATION_HISTORY }?${parameters}`;

    return await get(path, logged);
}

const getFeatures = async url => {
    return await call(url);
}

const authenticatedUrls = async () => {
    return await get(Urls.AUTHENTICATED_URL, true);
}

const anonymousUrls = async () => {
    return await get(Urls.ANONYMOUS_URL, false);
}

const get = async (path, isAuth) => {
    return await call(
        await createRequest('GET', path, isAuth)
    );
}

const post = async (path, data, isAuth) => {
    return await call(
        await createRequest('POST', path, isAuth, data)
    );
}

const _delete = async (path, isAuth) => {
    return await call(
        await createRequest('GET', path, isAuth)
    );
}

const createRequest = async (method, path, isAuth, data) => {
    let param = {
        method,
        url: config.apiUrl + path
    }

    if (isAuth) {
        param.headers = { Authorization : await getAuthorizationToken() }
    }

    if (data) { param.data = JSON.stringify(data) }

    return param;
}

const call = async (params) => {
    try {
        const response = await axios(params);
        return response.data;
    } catch (e) {
        return { status: 500, message: JSON.stringify(e)};
    }
}

const Urls = {
    ANONYMOUS_URL: '/anonymous/urls',
    AUTHENTICATED_URL: '/authenticated/urls',
    ANONYMOUS_STATION_INFO: '/anonymous/station/current',
    ANONYMOUS_STATION_HISTORY: '/anonymous/station/history',
    AUTHENTICATED_STATION_INFO: '/authenticated/station/current',
    AUTHENTICATED_STATION_HISTORY: '/authenticated/station/history'
}

export { getUrls, post, _delete, getStation, getStationHistory, getStationHistory24hr, getFeatures };
