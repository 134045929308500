import axios from 'axios';
import qs from 'query-string';
import config from '../config.json';

const retrieveTokens = async (authCode) => {
    const data = {
        'grant_type': 'authorization_code',
        'client_id': config.clientId,
        code: authCode,
        'redirect_uri': config.callbackUrl
    }
    return await postToken(data);
}

const refreshToken = async (authCode, refreshToken) => {
    const data = {
        'grant_type': 'refresh_token',
        'client_id': config.clientId,
        code: authCode,
        'scope': 'grant_type',
        'redirect_uri': config.callbackUrl,
        'refresh_token': refreshToken
    };
    return await postToken(data);
}

const postToken = async data => {
    return await call({
        method: 'POST',
        url: `${config.cognitoDomain}${Urls.OAUTH_TOKEN}`,
        data: qs.stringify(data),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const call = async (params) => {
    const response = await axios(params);
    return response.data;
}

const Urls = {
    OAUTH_TOKEN: '/oauth2/token'
}

export { retrieveTokens, refreshToken }