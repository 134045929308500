import { applyMiddleware, createStore } from 'redux';

import thunk from 'redux-thunk';
import actions from './actions';
import reducers from './reducers';

export default function store() {
    const _store = createStore(reducers, applyMiddleware(thunk.withExtraArgument(actions)));
    // logging
    /*
    _store.subscribe(()=>{
        console.log(_store.getState());
    });
    */
    return _store;
}
