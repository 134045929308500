import React, { Component } from 'react';
import radar from '../assets/radar.gif'
import './Preloader.css'

export default class ScatterGraph extends Component {
    render() {
        return (
            <div id="radar" {...this.props}>
                <img src={radar} alt="radar"/>
            </div>
        );
    }
}