import React from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes';

import { Provider } from 'react-redux'
import createStore from './store'

import './index.css'

const store = createStore();


ReactDOM.render(
    <Provider store={store}>
        <Routes/>
    </Provider>,
    document.getElementById('root')
);
