import React, { Component } from 'react';

import { Redirect } from "react-router-dom";
import Preloader from '../components/Preloader';
import { retrieveTokens } from '../lib/auth';
import qs from 'query-string';

export default class Callback extends Component {
    render() {
        const { code } = qs.parse(this.props.location.search);
        if (code) {
            retrieveTokens(code);
            return (
                <div style={{marginTop:'100px'}}>
                    <Preloader/>
                </div>
            )
        } else {
            return <Redirect to="/"/>
        }
    }
}
