import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Layout from './container/Layout';
import StationLayout from './container/StationLayout';
import WidgetLayout from './container/WidgetLayout';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import About from './components/About';
import Callback from './container/Callback';
import _404 from './container/404';
import Logout from './container/Logout';
import IEBrowserDetected from './components/IEBrowserDetected';
import { detect } from 'detect-browser';
const browser = detect();

const NonIEBrowserRoute = ({ component: Component, ..._props }) => {
    return (
        <Route {..._props} render={(props) => (
            (browser.name !== 'ie')
            ? <Component {..._props} />
            : <IEBrowserDetected/>
        )} />
    )
}

export default class Routes extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <NonIEBrowserRoute component={Layout} exact path="/"/>
                    <Route path="/station/:id/:type" component={StationLayout}/>
                    <Route path="/widget/:id" component={WidgetLayout}/>
                    <Route path="/privacypolicy" component={PrivacyPolicy}/>
                    <Route path="/termsofservice" component={TermsOfService}/>
                    <Route path="/about" component={About}/>
                    <Route path="/callback" component={Callback}/>
                    <Route path="/logout" component={Logout}/>
                    <Route component={_404} />
                </Switch>
            </Router>
        );
    }
}
