import React, { Component } from 'react';
import {Scatter} from 'react-chartjs-2';
import './Scatter.css';
import Preloader from '../Preloader'
import { Panel } from 'react-bootstrap'

import { evaluateCardinalDirection, getFilteredData, getWindSpeedGradient } from '../../lib/graph-util';

export default class ScatterGraph extends Component {
    getWindSpeed(canvas) {
        const { summary } = this.props,
            average = getFilteredData(summary, 'S'),
            max = getFilteredData(summary, 'SX'),
            gradient = getWindSpeedGradient(canvas, average, max);
        return {
            datasets: [{
                fill: true,
                lineTension: 0.3,
                borderColor: 'transparent',
                borderWidth: 1,
                backgroundColor: gradient,
                pointBorderColor: '#1481e0',
                pointBackgroundColor: '#1481e0',
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#1481e0',
                pointHoverBorderColor: '#1481e0',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 10,
                data: average,
                showLine: true
            }, {
                fill: false,
                lineTension: 0.3,
                borderColor: '#D3D3D3',
                borderDash: [10,5],
                borderWidth: 4,
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 10,
                data: getFilteredData(summary, 'SX'),
                showLine: true
            }]
        }
    }

    getWindDirection = () => {
        const { summary } = this.props;
        return {
            datasets: [{
                fill: false,
                lineTension: 0.3,
                borderColor: '#848484',
                borderWidth: 3,
                pointBorderColor: '#848484',
                pointBackgroundColor: '#848484',
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#848484',
                pointHoverBorderColor: '#848484',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 10,
                data: getFilteredData(summary, 'D'),
                showLine: true
            }]
        }
    }

    render() {
        const { summary } = this.props;
        if (summary && summary.length > 0) {
            const WindSpeedOptions = getWindSpeedOptions();            
            const WindDirectionOptions = getWindDirectionOptions();

            if (summary[summary.length - 1]["D"] > 270 || summary[summary.length - 1]["D"] < 90) { //switch scale
                WindDirectionOptions.scales.yAxes[0].ticks.min = -180;
                WindDirectionOptions.scales.yAxes[0].ticks.max = 180;
            }

            if (Number(new Date()) - (Number(summary[1]["UTC"])) > (60*60*12*1000)) { //switch scale
                WindSpeedOptions.scales.xAxes[0].time.stepSize = 6;
                WindSpeedOptions.scales.xAxes[0].time.displayFormats.hour = 'ddd - ha';
                WindDirectionOptions.scales.xAxes[0].time.stepSize = 6;
                WindDirectionOptions.scales.xAxes[0].time.displayFormats.hour = 'ddd - ha';
            }

            return (
                <Panel>
                    <Panel.Heading>
                        <Panel.Title componentClass="h2">Todays wind trend</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <div id="mini-graph" style={{height: '200px'}}>
                            <Scatter data={this.getWindSpeed.bind(this)} options={WindSpeedOptions} datasetKeyProvider={()=>Math.random()}/>
                        </div>
                        <div id="mini-graph" style={{height: '200px'}}>
                            <Scatter data={this.getWindDirection.bind(this)} options={WindDirectionOptions} datasetKeyProvider={()=>Math.random()}/>
                        </div>
                    </Panel.Body>
                </Panel>
            );
        } else if (summary.length === 0) {
            return <React.Fragment/>
        } else {
            return <Preloader/>
        }
    }
}

const getWindSpeedOptions = () => ({
    maintainAspectRatio: false,
    tooltipTemplate: v => v,
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'H:mm' },
                stepSize: 0.25,
                tooltipFormat: 'H:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                stepSize: 5,
                suggestedMin: 0
            }
        }]
    },
    legend: { display: false }
})

const getWindDirectionOptions = () => ({
    maintainAspectRatio: false,
    tooltipTemplate: v => v,
    scales: {
        xAxes: [{
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: { hour: 'H:mm' },
                stepSize: 0.25,
                tooltipFormat: 'H:mm'
            },
            display: true,
            ticks: { fontSize: 12 }
        }],
        yAxes: [{
            ticks: {
                fontSize: 12,
                stepSize: 90,
                min: -180,
                max: 360,
                callback: value => evaluateCardinalDirection(value)
            }
        }]
    },
    legend: { display: false }
});