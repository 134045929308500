const getFilteredData = (data, attribute) => {
    if (attribute === 'D' && (data[data.length - 1]["D"] > 270 || data[data.length - 1]["D"] < 90)){
        return (data.map((m)=>{
            const value = m[attribute];
            return {
                x: new Date(m.UTC),
                y: (value > 180) ? value - 360 : value
            }
        }));
    } else {
        return (data.map((m)=>({ x: new Date(m.UTC), y: m[attribute] })));
    }
}

const evaluateCardinalDirection = angle => {
    if (typeof angle === 'string') angle = parseInt(angle, 10);

    if (angle <= 0 && angle >= -180) angle = 360 + angle //Converts a negative Angle

    if (angle <= 0 || angle > 360 || typeof angle === 'undefined') return '☈';

    const arrows = {
            north: 'N ↓',
            north_east: 'NE ↙',
            east: 'E ←',
            south_east: 'SE ↖',
            south: 'S ↑',
            south_west: 'SW ↗',
            west: 'W →',
            north_west: 'NW ↘'
        },
        directions = Object.keys(arrows),
        degree = 360 / directions.length;

    angle = angle + degree / 2;

    for (let i = 0; i < directions.length; i++) {
        if (angle >= (i * degree) && angle < (i + 1) * degree) return arrows[directions[i]];
    }

    return arrows['north']; //Catch all
}

const getWindSpeedGradient = (canvas, data, dataMax) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 185, 0, -28);
    const colourConfig = [
            {'knot': 0.01, 'color': '#d3d3d3'}, // Light grey
            {'knot': 5, 'color': '#c6ffdd'}, // Light green
            {'knot': 15, 'color': '#FBD786'}, //yellwo (slight orange)
            {'knot': 25, 'color': '#f7797d'}, //light red
            {'knot': 35, 'color': '#1a2a6c'}, //dark Blue
            {'knot': 50, 'color': '#000000'} //Black
        ];

        let max
        if (dataMax[0].y) { 
            max = Math.ceil(Math.max.apply(null, dataMax.map(d=>d.y)) / 5 ) * 5;
        } else {
            max = Math.ceil(Math.max.apply(null, data.map(d=>d.y)) / 5 ) * 5;
        }

    colourConfig.forEach(function(ColorStop) {
        const stopPoint = ColorStop.knot / max;
        if (stopPoint > 0 && stopPoint <= 1) {
            gradient.addColorStop(stopPoint, ColorStop.color);
        }
    });

    return gradient;
}

export { getFilteredData, evaluateCardinalDirection, getWindSpeedGradient }
