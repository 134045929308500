import React, { Component } from 'react';
import { Panel, Image} from 'react-bootstrap'

export default class ScatterGraph extends Component {
    cameras(cameras) {
        let trs = [];
        
        const singleImageWidth = 100 / cameras.length 
        const imgStyle = {
            width: singleImageWidth + '%', marginRight: '0px', marginLeft: '0px'
        };
        cameras.forEach((camera,index)=>{
            if (camera.src) {
                const timestamp = new Date().getTime()
                const src = camera.src + '?timestamp=' + timestamp;
                trs.push(
                    <Image key={index} style={imgStyle} src={src} />
                )  
            }
            else if (camera.youtube) {
                const src = camera.youtube + '?rel=0&amp;controls=0&amp;showinfo=0';
                const title = 'This is the iframe for video ' + index
                trs.push(
                    <iframe title={title} key={index} width="500" height="281" src={src} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                ) 
            }
        })
        
        return trs;
    }

    render() {
        const { summary } = this.props;
        if (summary.cameras) {
            const width = '100%'

            const divStyle = { 
                width: width, 
                marginRight: 'auto', 
                marginLeft: 'auto'
            };

            return (
                <Panel>
                    <Panel.Heading>
                        <Panel.Title componentClass="h2">WebCams</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <div style={divStyle}>
                            {this.cameras(summary.cameras)}
                        </div>
                    </Panel.Body>
                </Panel>
            )
        } else {
            return <React.Fragment/>
        }

    }
}