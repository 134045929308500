import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ScatterGraph from '../components/WidgetLayout/ScatterGraph';
import CurrentStatus from '../components/WidgetLayout/CurrentStatus';
import WebCams from '../components/WidgetLayout/WebCams';
import Preloader from '../components/Preloader';

import { Glyphicon } from 'react-bootstrap';

import './WidgetLayout.css';


import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import { getStationHistory24hr } from '../lib/api'
export default class StationLayout extends Component {
    state = { data: undefined }

    componentDidMount = async () => {
        await this.load();
        const interval = setInterval(this.load.bind(this), 60000);
        this.setState({interval});
        //enableBodyScroll(this.targetElement);
        //enableBodyScroll(document.getElementById('station-page'));
        clearAllBodyScrollLocks();
    }

    componentWillUnmount = () => {
        clearInterval(this.state.interval);
    }

    load = async () => {
        const {id,type} = this.props.match.params;
        this.setState({ data: await getStationHistory24hr(id, type, 'Full') });
    }

    render() {
        const { data } = this.state;
        return (
            <div>
                {
                    (data)
                        ? <Display data={this.state.data}/>
                        : <Preloader style={{marginTop:300}}/>
                }
            </div>
        );
    }
}
const Display = ({data}) => {
    return (
        <React.Fragment>
            <div  id="station-page" >
                <CurrentStatus summary={data.weather[data.weather.length - 1]} />
                <ScatterGraph summary={data.weather} />
            </div>
        </React.Fragment>
    )
}
const styles = {
    imgDiv: {
        maxWidth: '20%'
    },
    headerDiv: {
        position:'relative',
        left:'10%'
    },
    imageHeader: {
        display: 'block',
        width: 'auto',
        maxHeight: 100,
        maxWidth: '100%',
        marginRight: 80,
        marginLeft: 20
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10
    },
    pageTitleBlock: {
        flexDirection:'row',
        display:'flex'
    },
    close: {
        alignSelf: 'flex-start'
    }
};