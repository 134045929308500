import React, { Component } from 'react';

export default class PrivacyPolicy extends Component {
    render() {
        return (
         <div style={{padding: '40px', fontSize:'150%'}}>
            <h1>About</h1>
            <p>I'm  a kitesurfer on the Mornington Peninsula and was frustrated that although there were heaps of good forecast sites getting actual live observations was a pain.
             The BOM stations were ok but not great and there were little weather stations in different locations (primarily yacht clubs) that it was hard easily find/access on
            mobile. I hence built this site to pull in the data from BOM, yacht clubs, vicports and other sources.
            Once I had the inital site working I found some locations were missing weather stations so I've built my own 4g/Solar stations that can easily be putup. (see note below re extra stations.)
            So far I don't have much of a plan on it, at some point I may putup some minimal sponsorship/advertising to help pay for running costs etc.</p>
            <h3>Feedback</h3>
            <p> I love feedback so please email me at <a href="mailto:Andrew.Thomas@weatherwatcher.com.au" target="_top">Andrew.Thomas@weatherwatcher.com.au</a> with your ideas</p>
            <h3>Extra Stations - Existing</h3>
            <p>If there is anyone already running a good weather station you'd like to see on the map, send me a link.</p>
            <h3>Extra Stations - New (Solar/4G)</h3>
            <p>If you have a location in mind that needs a solar/4g stations then email me above, preferably you already have access or a contact. Note if the location has wifi/power it does make it cheaper.</p>
         </div>
        );
    }
}
